import type {
  BlogHeroProps,
  BlogHeroSlideProps,
  BlogHeroResponsiveImageType,
} from '@we-make-websites/ui-lib';
import type {
  ModuleBlogHeroFragment,
  ModuleBlogHeroImageFragment,
} from '@/lib/contentful/__generated__/ModuleBlogHero';

/**
 * Formats the responsive image input.
 * @returns {Object|null}
 */
function formatImage(image: ModuleBlogHeroImageFragment) {
  const formattedImage: BlogHeroResponsiveImageType = {
    mobile: {
      alt: image.mobile?.title ?? '',
      src: image.mobile?.url ?? '',
      height: image.mobile?.height ?? 0,
      width: image.mobile?.width ?? 0,
    },
  };

  if (image.desktop) {
    formattedImage.desktop = {
      alt: image.desktop.title ?? '',
      src: image.desktop.url ?? '',
      height: image.desktop.height ?? 0,
      width: image.desktop.width ?? 0,
    };
  }

  return formattedImage;
}

export const formatModuleBlogHero = (
  entryContent: ModuleBlogHeroFragment
  // eslint-disable-next-line sonarjs/cognitive-complexity
): BlogHeroProps => {
  const slides: BlogHeroProps['slides'] = [];

  if (entryContent.slidesCollection?.items) {
    entryContent.slidesCollection.items.forEach((item) => {
      if (!item) {
        return;
      }

      /**
       * Maps slide images.
       */
      const images: BlogHeroSlideProps['images'] = [];

      item.imagesCollection?.items.forEach((image) => {
        if (!image) {
          return;
        }

        images.push(formatImage(image));
      });

      /**
       * Formats the slide props.
       */
      const formattedItem: BlogHeroSlideProps = {
        title: item.title ?? '',
        subtitle: item.subtitle ?? '',
        description: item.description ?? '',
        buttonLabel: item.buttonLabel ?? '',
        buttonLink: item.buttonLink ?? '',
        newTab: item.openLinkInNewTab ?? false,
        images,
        proOnly: entryContent.proContent ?? false,
      };

      if (item.featuredImage) {
        formattedItem.featuredImage = formatImage(item.featuredImage);
      }

      slides.push(formattedItem);
    });
  }

  return {
    slides,
  };
};
