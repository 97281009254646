import type { PlpDoubleWidthPromoFragment } from '@/lib/contentful/__generated__/PlpDoubleWidthPromo';
import type { PlpSingleWidthPromoFragment } from '@/lib/contentful/__generated__/PlpSingleWidthPromo';
import type { ShareYourLooksFragment } from '@/lib/contentful/__generated__/ShareYourLooks';
import type {
  CollectionInGridContentCollection,
  InGridContentItem,
  ShelfInGridContentCollection,
} from '@/lib/contentful/__generated__/types';
import type { VideoSectionFragment } from '@/lib/contentful/__generated__/VideoSection';
import type { InGridContent } from '../types/contentfulInGridContent';
import { formatGetLookSection } from './contentful/formatGetLooksection';
import { formatManualProductRecommendations } from './contentful/formatManualProductRecommendations';
import { formatPlpDoubleWidthPromo } from './contentful/formatPlpDoubleWidthPromo';
import { formatPlpSingleWidthPromo } from './contentful/formatPlpSingleWidthPromo';
import { formatShareYourLooks } from './contentful/formatShareYourLooks';
import { formatVideoSection } from './contentful/formatVideoSection';

export const formatInGridContent = (
  originalData:
    | CollectionInGridContentCollection['items']
    | ShelfInGridContentCollection['items']
) => {
  const inGridContent = [] as InGridContent[];

  if (originalData) {
    originalData.forEach((inGridContentEntry) => {
      if (!inGridContentEntry?.content?.__typename) return;

      const formattedInGridContentEntry = formatInGridEntry(inGridContentEntry);

      if (!formattedInGridContentEntry) return;

      inGridContent.push(formattedInGridContentEntry);
    });
  }

  return inGridContent;
};

/**
 * Format In Grid Component data to the right shape per component.
 * @param inGridContentEntry - In grid content contentful data.
 */
const formatInGridEntry = (inGridContentEntry: InGridContentItem) => {
  if (inGridContentEntry.content?.__typename === undefined) {
    return;
  }

  const entryContent = inGridContentEntry.content;
  const position = inGridContentEntry.position;

  if (!position) {
    return;
  }

  const defaultData = {
    custom: true as const,
    position,
    size: getInGridContentSize(inGridContentEntry),
  };

  switch (entryContent?.__typename) {
    case 'PlpDoubleWidthPromo': {
      const type: InGridContent['type'] = 'PLPPromo';
      const content = formatPlpDoubleWidthPromo(
        entryContent as PlpDoubleWidthPromoFragment
      );

      return {
        ...defaultData,
        type,
        content,
      };
    }

    case 'PlpSingleWidthPromo': {
      const type: InGridContent['type'] = 'PLPPromo';
      const content = formatPlpSingleWidthPromo(
        entryContent as PlpSingleWidthPromoFragment
      );

      return {
        ...defaultData,
        type,
        content,
      };
    }

    case 'ShareYourLooks': {
      const type: InGridContent['type'] = 'ShareYourLooks';
      const content = formatShareYourLooks(
        entryContent as ShareYourLooksFragment
      );

      return {
        ...defaultData,
        type,
        content,
      };
    }

    case 'VideoSection': {
      const type: InGridContent['type'] = 'VideoPlayer';
      const content = formatVideoSection(entryContent as VideoSectionFragment);

      return {
        ...defaultData,
        type,
        content,
      };
    }

    case 'ManualProductRecommendations': {
      const type: InGridContent['type'] = 'ManualProductRecommendations';
      const content = formatManualProductRecommendations(entryContent);

      return {
        ...defaultData,
        type,
        content,
      };
    }

    case 'GetLook': {
      const type: InGridContent['type'] = 'GetLookSection';
      const content = formatGetLookSection(entryContent);

      return {
        ...defaultData,
        type,
        content,
      };
    }
  }
};

/**
 * Generate the sizes for the in-grid content based on typename.
 * @param inGridContentEntry - Single in grid content data
 * @returns size data
 */
export const getInGridContentSize = (inGridContentEntry: InGridContentItem) => {
  const entryContent = inGridContentEntry.content;

  if (!entryContent) {
    return {
      mobile: 0,
      desktop: 0,
    };
  }

  switch (entryContent.__typename) {
    case 'VideoSection':
    case 'PlpDoubleWidthPromo': {
      return {
        mobile: 2,
        desktop: 2,
      };
    }

    case 'PlpSingleWidthPromo': {
      return {
        mobile: 1,
        desktop: 1,
      };
    }

    case 'ShareYourLooks':
    case 'ManualProductRecommendations':
    case 'GetLook': {
      return {
        mobile: 2,
        desktop: 4,
      };
    }

    default: {
      return {
        mobile: 0,
        desktop: 0,
      };
    }
  }
};
