import type {
  EducationCardProps,
  ServiceEducationListingProps,
} from '@we-make-websites/ui-lib';
import type { ServiceEducationListingFragment } from '@/lib/contentful/__generated__/ServiceEducationListing';

export const formatServiceEducationListing = (
  entryContent: ServiceEducationListingFragment
): ServiceEducationListingProps => {
  return {
    title: entryContent.title ?? '',
    categoryTitle: entryContent.categoryTitle ?? '',
    description: entryContent.description ?? '',
    subtitle: entryContent.subtitle ?? '',
    subtitleNumber: entryContent.subtitleNumber ?? '',
    ctaLabel: entryContent.ctaText ?? '',
    ctaUrl: entryContent.ctaLink ?? '',
    newTab: entryContent.openLinkInNewTab ?? false,
    items: entryContent.itemsCollection?.items
      .filter((item) => {
        return item && item.title && item.image && item.excerpt;
      })
      .map((item) => {
        const itemProps: EducationCardProps = {
          title: item?.title ?? '',
          excerpt: item?.excerpt ?? '',
          ctaTitle: item?.ctaTitle ?? '',
          ctaUrl: item?.ctaLink ?? '',
          isPro: item?.proContent ?? false,
        };

        if (item?.image) {
          itemProps.image = {
            src: item.image.url ?? '',
            height: item.image.height ?? 0,
            width: item.image.width ?? 0,
            alt: item.image.title ?? '',
          };
        }

        return itemProps;
      }),
  };
};
