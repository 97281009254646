import type { PLPPromoProps } from '@we-make-websites/ui-lib';
import type { PlpSingleWidthPromoFragment } from '@/lib/contentful/__generated__/PlpSingleWidthPromo';

export const formatPlpSingleWidthPromo = (
  entryContent: PlpSingleWidthPromoFragment
): PLPPromoProps => {
  return {
    title: entryContent.title ?? '',
    subtitle: entryContent.subtitle ?? '',
    description: entryContent.description ?? '',
    cta:
      entryContent.ctaUrl && entryContent.ctaTitle
        ? {
            url: entryContent.ctaUrl ?? '',
            title: entryContent.ctaTitle ?? '',
            newTab: entryContent.openLinkInNewTab ?? false,
          }
        : undefined,
    contentPlacement:
      entryContent.contentPlacement as PLPPromoProps['contentPlacement'],
    image: {
      width: entryContent.image?.width ?? 0,
      height: entryContent.image?.height ?? 0,
      title: entryContent.image?.title ?? '',
      url: entryContent.image?.url ?? '',
    },
    isLoading: false,
  };
};
