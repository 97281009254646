import type { VideoHeroProps } from '@we-make-websites/ui-lib';
import type { VideoHeroFragment } from '@/lib/contentful/__generated__/VideoHero';
import type { VideoSectionFragment } from '@/lib/contentful/__generated__/VideoSection';
import { formatVideoSection } from './formatVideoSection';

export const formatVideoHero = (
  entryContent: VideoHeroFragment
): VideoHeroProps => {
  return {
    videoPlayerProps: formatVideoSection(
      entryContent.video as VideoSectionFragment
    ),
    backgroundColor: entryContent.backgroundColor || '',
    dotsBackground: entryContent.dotsBackground || '',
    subtitle: entryContent.subtitle || '',
    subtitleReverse: formatBooleanField(entryContent.subtitleReverse),
    title: entryContent.title || '',
    titleReverse: formatBooleanField(entryContent.titleReverse),
    description: entryContent.description || '',
    descriptionReverse: formatBooleanField(entryContent.descriptionReverse),
    disclaimer: entryContent.disclaimer || '',
    disclaimerReverse: formatBooleanField(entryContent.disclaimerReverse),
    buttonLink: entryContent.buttonLink || '',
    buttonText: entryContent.buttonText || '',
    newTab: entryContent.openLinkInNewTab || false,
  };
};

/**
 * Format contentful radio button field to boolean.
 * @param value - contentful radio button value.
 */
function formatBooleanField(value: string | null | undefined) {
  return value === 'true' ? true : false;
}
