import type { CollectionVideoSectionProps } from '@we-make-websites/ui-lib';
import type { CollectionVideoSectionFragment } from '@/lib/contentful/__generated__/CollectionVideoSection';
import type { VideoSectionFragment } from '@/lib/contentful/__generated__/VideoSection';
import { formatVideoSection } from './formatVideoSection';

export const formatVideoCollectionSection = (
  entryContent: CollectionVideoSectionFragment
): CollectionVideoSectionProps => {
  const videoSectionProps = formatVideoSection(
    entryContent.videoSection as VideoSectionFragment
  );

  return {
    title: entryContent.title ?? '',
    tagline: entryContent.tagline ?? '',
    description: entryContent.description ?? '',
    image: {
      title: entryContent.image?.title || '',
      url: entryContent.image?.url || '',
      height: entryContent.image?.height || 0,
      width: entryContent.image?.width || 0,
    },
    cta: {
      title: entryContent.ctaTitle ?? '',
      url: entryContent.ctaUrl ?? '',
      newTab: entryContent.openLinkInNewTab ?? false,
    },
    layout: (entryContent.layout ??
      'regular') as CollectionVideoSectionProps['layout'],
    isLoading: false,
    ...videoSectionProps,
  };
};
