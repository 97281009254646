import type { GetLookSectionProps, ImageType } from '@we-make-websites/ui-lib';
import type { GetLookFragment } from '@/lib/contentful/__generated__/GetLook';

export const formatGetLookSection = (
  entryContent: GetLookFragment
): GetLookSectionProps => {
  return {
    textContent: {
      title: entryContent.title ?? '',
      subtitle: entryContent.subtitle ?? '',
      descriptionDesktop: entryContent.descriptionDesktop ?? '',
      descriptionMobile: entryContent.descriptionMobile ?? '',
      ctaTitle: entryContent.ctaTitle ?? '',
      ctaUrl: entryContent.ctaLink ?? '',
    },
    textContentSide: entryContent.textContentSide === 'left' ? 'left' : 'right',
    leftImage: getImageData(entryContent.leftImage),
    rightImage: getImageData(entryContent.rightImage),
  };
};

const getImageData = (
  image:
    | {
        url?: string | null | undefined;
        title?: string | null | undefined;
        width?: number | null | undefined;
        height?: number | null | undefined;
      }
    | null
    | undefined
): ImageType | undefined => {
  return image?.url
    ? {
        title: image?.title ?? '',
        url: image?.url ?? '',
        height: image?.height ?? 0,
        width: image?.width ?? 0,
      }
    : undefined;
};
