import type { InstagramEmbedProps } from '@we-make-websites/ui-lib';
import type { InstagramEmbedFragment } from '@/lib/contentful/__generated__/InstagramEmbed';

export const formatInstagramEmbed = (
  entryContent: InstagramEmbedFragment
): InstagramEmbedProps => {
  return {
    code: entryContent.code ?? '',
  };
};
