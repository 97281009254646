import type {
  TestimoniesItemProps,
  TestimoniesProps,
} from '@we-make-websites/ui-lib';
import type { ModuleTestimoniesFragment } from '@/lib/contentful/__generated__/ModuleTestimonies';

export const formatModuleTestimonies = (
  entryContent: ModuleTestimoniesFragment
): TestimoniesProps => {
  return {
    title: entryContent.title ?? '',
    items: entryContent.itemsCollection?.items.filter(Boolean).map((item) => {
      const fields: TestimoniesItemProps = {
        quote: item?.quote ?? '',
        name: item?.name ?? '',
      };

      if (item?.image) {
        fields.image = {
          src: item.image.url ?? '',
          height: item.image.height ?? 0,
          width: item.image.width ?? 0,
          alt: item.image.title ?? '',
        };
      }

      return fields;
    }),
  };
};
