import type { VideoSectionTabsListType } from '@we-make-websites/ui-lib';
import type { ModuleCollectionVideoLessonTabsFragment } from '@/lib/contentful/__generated__/ModuleCollectionVideoLessonTab';

export const formatVideoSectionTabs = (
  entryContent: ModuleCollectionVideoLessonTabsFragment
): VideoSectionTabsListType => {
  const sectionTabs: VideoSectionTabsListType = { tabs: [] };

  (entryContent.videoLessonsCollection?.items || [])
    .filter((item) => {
      return (
        item &&
        item.title &&
        (item.video?.video ||
          item.video?.vimeoVideoId ||
          item.video?.youtubeVideoId)
      );
    })
    .forEach((item) => {
      sectionTabs.tabs.push({
        title: item?.title ?? '',
        video: {
          title: item?.title ?? '',
          lessonNumber: item?.lessonNumber ?? 0,
          content: item?.description ?? '',
          buttonLabel: item?.buttonLabel ?? '',
          buttonLink: item?.buttonLink ?? '',
          video: {
            video: {
              url: item?.video?.video?.url ?? '',
              contentType: item?.video?.video?.contentType ?? '',
            },
            youtubeVideoId: item?.video?.youtubeVideoId ?? '',
            vimeoVideoId: item?.video?.vimeoVideoId ?? '',
            poster: {
              title: item?.video?.videoPoster?.title ?? '',
              url: item?.video?.videoPoster?.url ?? '',
              height: item?.video?.videoPoster?.height ?? 0,
              width: item?.video?.videoPoster?.width ?? 0,
            },
          },
        },
      });
    });

  return sectionTabs;
};
