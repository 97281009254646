import type { VideoPlayerProps } from '@we-make-websites/ui-lib';
import type { VideoSectionFragment } from '@/lib/contentful/__generated__/VideoSection';

export const formatVideoSection = (
  entryContent: VideoSectionFragment
): VideoPlayerProps => {
  return {
    video: {
      url: entryContent.video?.url || '',
      contentType: entryContent.video?.contentType || '',
    },
    youtubeVideoId: entryContent.youtubeVideoId || '',
    vimeoVideoId: entryContent.vimeoVideoId || '',
    poster: {
      title: entryContent.videoPoster?.title || '',
      url: entryContent.videoPoster?.url || '',
      height: entryContent.videoPoster?.height || 0,
      width: entryContent.videoPoster?.width || 0,
    },
  };
};
