import type { CreatorOfMonthProps } from '@we-make-websites/ui-lib';
import type { CreatorOfMonthFragment } from '@/lib/contentful/__generated__/CreatorOfMonth';

export const formatMonthCreator = (
  entryContent: CreatorOfMonthFragment
): CreatorOfMonthProps => {
  return {
    logo: formatImageMediaField(entryContent.logo),
    profileImage: formatImageMediaField(entryContent.profileImage),
    featuredMobileImage: formatImageMediaField(
      entryContent.featuredMobileImage
    ),
    featuredDesktopImage: formatImageMediaField(
      entryContent.featuredDesktopImage
    ),
    creatorName: entryContent.creatorName || '',
    subtitleText: entryContent.subtitleText || '',
    descriptionText: entryContent.descriptionText || '',
    disclaimerContent: entryContent.disclaimerContent || '',
    disclaimerCtaUrl: entryContent.disclaimerCtaUrl || '',
    disclaimerCtaText: entryContent.disclaimerCtaText || '',
    ctaTitle: entryContent.ctaTitle || '',
    ctaUrl: entryContent.ctaUrl || '',
    newTab: entryContent.openLinkInNewTab || false,
  };
};

/**
 * Format contentful image media field to appropriate format.
 * @param image - contentful image media field value.
 */
function formatImageMediaField(image: CreatorOfMonthFragment['logo']) {
  if (!image) return undefined;

  return {
    title: image.title || '',
    url: image.url || '',
    height: image.height || 0,
    width: image.width || 0,
  };
}
