import type { HeroSlideProps } from '@we-make-websites/ui-lib';
import type { MainHeroFragment } from '@/lib/contentful/__generated__/MainHero';

export const formatMainHero = (
  entryContent: MainHeroFragment
): HeroSlideProps => {
  return {
    desktopImage: formatImageMediaField(entryContent.desktopImage),
    mobileImage: formatImageMediaField(entryContent.mobileImage),
    productImage: formatImageMediaField(entryContent.productImage),
    desktopImageCover: formatBooleanField(entryContent.desktopImageCover),
    mobileImageCover: formatBooleanField(entryContent.mobileImageCover),
    backgroundColor: entryContent.backgroundColor || '',
    dotsBackground: entryContent.dotsBackground || '',
    subtitle: entryContent.subtitle || '',
    subtitleReverse: formatBooleanField(entryContent.subtitleReverse),
    title: formatTextNewLine(entryContent.title),
    titleReverse: formatBooleanField(entryContent.titleReverse),
    description: entryContent.description || '',
    descriptionReverse: formatBooleanField(entryContent.descriptionReverse),
    disclaimer: entryContent.disclaimer || '',
    disclaimerReverse: formatBooleanField(entryContent.disclaimerReverse),
    buttonLink: entryContent.buttonLink || '',
    buttonText: entryContent.buttonText || '',
    newTab: entryContent.openLinkInNewTab || false,
    mobileTextReverse: formatBooleanField(entryContent.mobileTextReverse),
  };
};

/**
 * Format string value to convert \n into break tags
 * @param value - string
 * @returns string
 */
function formatTextNewLine(value: string | null | undefined): string {
  if (!value) return '';

  return value.replace(/\n/g, '<br />');
}

/**
 * Format contentful radio button field to boolean.
 * @param value - contentful radio button value.
 */
function formatBooleanField(value: string | null | undefined) {
  return value === 'true' ? true : false;
}

/**
 * Format contentful image media field to appropriate format.
 * @param image - contentful image media field value.
 */
function formatImageMediaField(image: MainHeroFragment['desktopImage']) {
  if (!image) return undefined;

  return {
    alt: image.title || '',
    src: image.url || '',
    height: image.height || 0,
    width: image.width || 0,
  };
}
