import type { FindYourSystemProps } from '@we-make-websites/ui-lib';
import type { FindYourSystemFragment } from '@/lib/contentful/__generated__/FindYourSystem';
import type { IntroCardFragment } from '@/lib/contentful/__generated__/IntroCard';
import type { SystemCardFragment } from '@/lib/contentful/__generated__/SystemCard';
import type { SystemListingFragment } from '@/lib/contentful/__generated__/SystemListing';

export const formatFindYourSystem = (
  entryContent: FindYourSystemFragment
): FindYourSystemProps => {
  const systemListings = [] as FindYourSystemProps['systemListings'];

  if (entryContent.systemListingsCollection?.items) {
    entryContent.systemListingsCollection.items.forEach((entryTab) => {
      if (!entryTab) {
        return;
      }

      const tabData = formatSystemListing(entryTab);
      systemListings.push(tabData);
    });
  }
  return {
    title: entryContent.title ?? '',
    systemListings,
    isLoading: false,
  };
};

const formatSystemListing = (
  entryTabContent: SystemListingFragment
): FindYourSystemProps['systemListings'][0] => {
  const introCard = entryTabContent.introCard
    ? formatIntroCard(entryTabContent.introCard)
    : ({} as FindYourSystemProps['systemListings'][0]['introCard']);

  const systemCards = entryTabContent.systemCardCollection?.items
    ? formatSystemCards(entryTabContent.systemCardCollection.items)
    : [];

  return {
    title: entryTabContent.title ?? '',
    introCard,
    systemCards,
  };
};

const formatIntroCard = (
  introCardContent: IntroCardFragment
): FindYourSystemProps['systemListings'][0]['introCard'] => {
  return {
    title: introCardContent.title ?? '',
    callToAction: introCardContent.callToAction ?? '',
    contentColor: introCardContent.contentColor ?? '',
    image: {
      url: introCardContent.image?.url ?? '',
      width: introCardContent.image?.width ?? 0,
      height: introCardContent.image?.height ?? 0,
      title: introCardContent.image?.title ?? '',
    },
  };
};

const formatSystemCards = (
  systemCardItems: (SystemCardFragment | null)[]
): FindYourSystemProps['systemListings'][0]['systemCards'] => {
  const content = [] as FindYourSystemProps['systemListings'][0]['systemCards'];

  systemCardItems.forEach((systemCardItem) => {
    if (!systemCardItem || !systemCardItem.title) return;
    const systemCardData = formatSystemCardItem(systemCardItem);
    content.push(systemCardData);
  });

  return content;
};

const formatSystemCardItem = (
  systemCardItem: SystemCardFragment
): FindYourSystemProps['systemListings'][0]['systemCards'][0] => {
  const icons =
    [] as FindYourSystemProps['systemListings'][0]['systemCards'][0]['icons'];

  if (systemCardItem.icons?.items) {
    systemCardItem.icons.items.forEach((icon) => {
      const iconData = {
        url: icon?.url ?? '',
        width: icon?.width ?? 0,
        height: icon?.height ?? 0,
        title: icon?.title ?? '',
      };

      icons.push(iconData);
    });
  }

  return {
    title: systemCardItem.title ?? '',
    description: systemCardItem.description ?? '',
    contentColor: systemCardItem.contentColor ?? '',
    productImage: {
      url: systemCardItem.productImage?.url ?? '',
      width: systemCardItem.productImage?.width ?? 0,
      height: systemCardItem.productImage?.height ?? 0,
      title: systemCardItem.productImage?.title ?? '',
    },
    cta: getCardCta(systemCardItem),
    systemColor: systemCardItem.systemColor ?? '',
    icons,
  };
};

const getCardCta = (
  systemCardItem: SystemCardFragment
): {
  title: string;
  url: string;
  newTab: boolean;
} => {
  return {
    title: systemCardItem.ctaTitle ?? '',
    url: systemCardItem.ctaUrl ?? '',
    newTab: systemCardItem.openLinkInNewTab ?? false,
  };
};
