/* eslint-disable sonarjs/cognitive-complexity */
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import type { EducationCourseListingProps } from '@/components/educationCourseListing/EducationCourseListing';
import type { EduCourseListingFragment } from '@/lib/contentful/__generated__/ModuleEduCourseListing';

export const formatEduCourseListing = (
  entryContent: EduCourseListingFragment
): EducationCourseListingProps => {
  const content = entryContent.module?.tutorialContent?.json
    ? documentToReactComponents(
        entryContent.module?.tutorialContent?.json as Document
      )
    : undefined;

  return {
    title: entryContent.title ?? '',
    module:
      entryContent.module &&
      (entryContent.module.subtitle || entryContent.module.title)
        ? {
            title: entryContent.module.title ?? '',
            subtitle: entryContent.module.subtitle ?? '',
            content,
            contentLarge: entryContent.module.contentLarge ?? false,
            buttonLabel: entryContent.module.buttonLabel ?? '',
            buttonLink: entryContent.module.buttonLink ?? '',
            image: entryContent.module.image
              ? {
                  alt: entryContent.module.image.title ?? '',
                  src: entryContent.module.image.url ?? '',
                  height: entryContent.module.image.height ?? 0,
                  width: entryContent.module.image.width ?? 0,
                }
              : undefined,
          }
        : undefined,
  };
};
