import type { HowToApplyProps } from '@we-make-websites/ui-lib';
import type { ModuleHowToApplyFragment } from '@/lib/contentful/__generated__/ModuleHowToApply';

export const formatModuleHowToApply = (
  entryContent: ModuleHowToApplyFragment
): HowToApplyProps => {
  const items: HowToApplyProps['items'] = [];

  if (entryContent.itemsCollection?.items) {
    entryContent.itemsCollection.items.forEach((item) => {
      if (!item) {
        return;
      }

      const formattedItem = {
        title: item.title ?? '',
        description: item.description ?? '',
        video: {
          video: {
            url: item.video?.video?.url ?? '',
            contentType: item.video?.video?.contentType ?? '',
          },
          youtubeVideoId: item.video?.youtubeVideoId ?? '',
          vimeoVideoId: item.video?.vimeoVideoId ?? '',
          poster: {
            title: item.video?.videoPoster?.title ?? '',
            url: item.video?.videoPoster?.url ?? '',
            height: item.video?.videoPoster?.height ?? 0,
            width: item.video?.videoPoster?.width ?? 0,
          },
        },
      };

      items.push(formattedItem);
    });
  }

  return {
    items,
  };
};
