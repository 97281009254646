import type { NailArtSectionProps } from '@we-make-websites/ui-lib';
import { WishlistButtonConnected } from '@/components/wishlistButton/WishlistButtonConnected';
import type { NailArtSectionFragment } from '@/lib/contentful/__generated__/SectionNailArt';

export const formatNailArtSection = (
  entryContent: NailArtSectionFragment
): NailArtSectionProps => {
  const mappedData: NailArtSectionProps['tabs'] = [];

  const nailArtSectionData = {
    title: entryContent.title ?? '',
    subtitle: entryContent.subtitle ?? '',
    tabs: mappedData,
    wishlistComponent: WishlistButtonConnected,
  };

  if (!entryContent.tabsCollection?.items.length) return nailArtSectionData;

  entryContent.tabsCollection?.items.forEach((tab) => {
    if (!tab?.tabCardsCollection) return;

    const refinedCardProps = tab.tabCardsCollection.items.map((card) => {
      return {
        image: card?.image?.url ?? '',
        imageTitle: card?.image?.title ?? '',
        title: card?.title ?? '',
        url: card?.url ?? '',
        newTab: card?.openLinkInNewTab ?? false,
        isLoading: false,
        proOnly: Boolean(card?.proOnly),
        author: card?.author ?? '',
        description: card?.description ?? '',
      };
    });

    mappedData.push({
      title: tab?.tabTitle || '',
      link: tab?.tabLink || '',
      nailArtCardTab: refinedCardProps,
    });
  });

  return nailArtSectionData;
};
