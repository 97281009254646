import type { NotFoundHeroProps } from '@we-make-websites/ui-lib';
import type { NotFoundHeroFragment } from '@/lib/contentful/__generated__/NotFoundHero';

export const formatNotFoundHero = (
  entryContent: NotFoundHeroFragment
): NotFoundHeroProps => {
  return {
    title: entryContent.title ?? '',
    subtitle: entryContent.subtitle ?? '',
    message: entryContent.message ?? '',
    image: {
      title: entryContent.desktopImage?.title || '',
      url: entryContent.desktopImage?.url || '',
      height: entryContent.desktopImage?.height || 0,
      width: entryContent.desktopImage?.width || 0,
    },
    imageMobile: {
      title: entryContent.mobileImage?.title || '',
      url: entryContent.mobileImage?.url || '',
      height: entryContent.mobileImage?.height || 0,
      width: entryContent.mobileImage?.width || 0,
    },
    button: {
      title: entryContent.buttonText ?? '',
      url: entryContent.buttonLink ?? '',
      newTab: entryContent.openLinkInNewTab ?? false,
    },
  };
};
