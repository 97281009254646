import type { BlogArticleTextImageProps } from '@we-make-websites/ui-lib';
import type { BlogArticleTextImageFragment } from '@/lib/contentful/__generated__/ModuleBlogArticleTextImage';

export const formatBlogArticleTextImage = (
  entryContent: BlogArticleTextImageFragment
): BlogArticleTextImageProps => {
  return {
    title: entryContent.title ?? '',
    content: entryContent.content ?? '',
    image: {
      height: entryContent.image?.height ?? 0,
      width: entryContent.image?.width ?? 0,
      title: entryContent.image?.title ?? '',
      url: entryContent.image?.url ?? '',
    },
  };
};
