import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import type {
  EducationLinkItem,
  EducationLinkProps,
} from '@we-make-websites/ui-lib';
import type { ModuleEducationLinkFragment } from '@/lib/contentful/__generated__/ModuleEducationLink';

export const formatModuleEducationLink = (
  entryContent: ModuleEducationLinkFragment
): EducationLinkProps => {
  return {
    title: entryContent.title ?? '',
    items: entryContent.itemsCollection?.items.filter(Boolean).map((item) => {
      const itemProps: EducationLinkItem = {
        title: item?.title ?? '',
        subtitle: item?.subtitle ?? '',
        newTab: item?.openLinkInNewTab ?? false,
        content:
          item?.content &&
          documentToReactComponents(item.content.json as Document),
        buttonLabel: item?.buttonLabel ?? '',
        buttonLink: item?.buttonLink ?? '',
      };

      if (item?.image) {
        itemProps.image = {
          src: item.image.url ?? '',
          height: item.image.height ?? 0,
          width: item.image.width ?? 0,
          alt: item.image.title ?? '',
        };
      }

      return itemProps;
    }),
  };
};
