import type { ImageProps } from 'next/image';
import type { CollectionListingWrapperProps } from '@/components/collectionListingWrapper/CollectionListingWrapper';
import type { CollectionFeaturedListingSectionFragment } from '@/lib/contentful/__generated__/CollectionFeaturedListing';

export const formatCollectionFeaturedListing = (
  entryContent: CollectionFeaturedListingSectionFragment
): CollectionListingWrapperProps => {
  return {
    title: entryContent.title || '',
    message: entryContent.message || '',
    buttonText: entryContent.buttonText || '',
    buttonLink: entryContent.buttonUrl || '',
    newTab: entryContent.openLinkInNewTab || false,
    image: formatImageMediaField(entryContent.backgroundImage) as ImageProps,
    backgroundColor: entryContent.backgroundColour || '',
    products: formatProductsField(entryContent.productsCollection),
  };
};

/**
 * Format contentful image media field to appropriate format.
 * @param image - contentful image media field value.
 */
function formatImageMediaField(
  image: CollectionFeaturedListingSectionFragment['backgroundImage']
) {
  if (!image) return undefined;

  return {
    alt: image.title || '',
    src: image.url || '',
    height: image.height || 0,
    width: image.width || 0,
  };
}

/**
 * Format contentful image media field to appropriate format.
 * @param image - contentful image media field value.
 */
function formatProductsField(
  productsCollection: CollectionFeaturedListingSectionFragment['productsCollection']
): CollectionListingWrapperProps['products'] {
  if (!productsCollection) return [];

  return productsCollection.items.map((product) => {
    return {
      title: product?.title || '',
      shopifyId: product?.shopifyId || '',
      handle: product?.handle || '',
    };
  });
}
