import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import type { Document } from '@contentful/rich-text-types';
import { useQueries } from '@tanstack/react-query';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { createContext } from 'use-context-selector';
import type { ImageType } from '@/components/plpContext/types/storePLP';
import { useStaticPageQuery } from '@/lib/contentful/__generated__/StaticPageQuery';
import type {
  StaticPageQuery,
  StaticPageQueryVariables,
} from '@/lib/contentful/__generated__/StaticPageQuery';
import type { StaticPageAdditionalSectionsQuery } from '@/lib/contentful/__generated__/StaticPageQueryAdditionalSections';
import { useStaticPageAdditionalSectionsQuery } from '@/lib/contentful/__generated__/StaticPageQueryAdditionalSections';
import { useStaticPageAdditionalSectionsPlusQuery } from '@/lib/contentful/__generated__/StaticPageQueryAdditionalSectionsPlus';
import { useStaticPageAdditionalSectionsPlusTwoQuery } from '@/lib/contentful/__generated__/StaticPageQueryAdditionalSectionsPlusTwo';
import { useStaticPageHtmlQuery } from '@/lib/contentful/__generated__/StaticPageQueryHtml';
import { useStaticPageHtmlImageQuery } from '@/lib/contentful/__generated__/StaticPageQueryHtmlImage';
import type { StaticPageContentLinks } from '@/lib/contentful/__generated__/types';
import { contentfulQueryDataSource } from '@/lib/contentful/dataSources';
import { storeLocale } from '@/root/constants';
import { getContentfulTagFilter } from '@/utils/contentfulHelpers';
import { formatContentfulSections } from '@/utils/format/contentful/formatContentfulSections';
import type {
  FormattedContentfulSection,
  InputContentfulSection,
  InputContentfulSections,
} from '@/utils/types/contentfulSections';
import type { StoreSeo } from '@/utils/types/storeTypes';
import styles from '../staticPageContent/StaticPageContent.module.scss';

export type StoreStaticPage = {
  seo?: StoreSeo;
  content?: ReactNode;
  image?: ImageType;
  title?: string;
  sections?: FormattedContentfulSection[];
};

export type StaticPageContext = StoreStaticPage & {
  isLoading: boolean;
};

export const StaticPageContext = createContext<StaticPageContext | undefined>(
  undefined
);

export function StaticPageProvider({
  handle,
  children,
}: {
  handle: string;
  children: ReactNode;
}) {
  const { locale: routerLocale, query } = useRouter();

  const locale = storeLocale(routerLocale);
  const preview = typeof query.preview === 'string' ? !!query.preview : false;

  const variables: StaticPageQueryVariables = {
    handle,
    locale,
    preview,
    tagFilter: preview ? {} : getContentfulTagFilter(locale),
  };

  const staticPageQueries = useQueries({
    queries: [
      {
        queryKey: useStaticPageQuery.getKey(variables),
        queryFn: useStaticPageQuery.fetcher(
          contentfulQueryDataSource({ byPassCache: preview }),
          variables
        ),
      },
      {
        queryKey: useStaticPageAdditionalSectionsQuery.getKey(variables),
        queryFn: useStaticPageAdditionalSectionsQuery.fetcher(
          contentfulQueryDataSource({ byPassCache: preview }),
          variables
        ),
      },
      {
        queryKey: useStaticPageAdditionalSectionsPlusQuery.getKey(variables),
        queryFn: useStaticPageAdditionalSectionsPlusQuery.fetcher(
          contentfulQueryDataSource({ byPassCache: preview }),
          variables
        ),
      },
      {
        queryKey: useStaticPageAdditionalSectionsPlusTwoQuery.getKey(variables),
        queryFn: useStaticPageAdditionalSectionsPlusTwoQuery.fetcher(
          contentfulQueryDataSource({ byPassCache: preview }),
          variables
        ),
      },
      {
        queryKey: useStaticPageHtmlQuery.getKey(variables),
        queryFn: useStaticPageHtmlQuery.fetcher(
          contentfulQueryDataSource({ byPassCache: preview }),
          variables
        ),
      },
      {
        queryKey: useStaticPageHtmlImageQuery.getKey(variables),
        queryFn: useStaticPageHtmlImageQuery.fetcher(
          contentfulQueryDataSource({ byPassCache: preview }),
          variables
        ),
      },
    ],
  });

  const isLoading = staticPageQueries.some((query) => query.isLoading);
  const queriesData = staticPageQueries.map((query) => query.data);

  const data = formatStaticPage(queriesData);

  const contextValue = useMemo(() => {
    if (data) {
      return {
        ...data,
        handle,
        isLoading,
      };
    }

    return {
      handle,
      isLoading,
    };
  }, [data, handle, isLoading]);

  return (
    <StaticPageContext.Provider value={contextValue}>
      {children}
    </StaticPageContext.Provider>
  );
}

/**
 * Static page data formatter.
 * @param originalData - StaticPageQuery
 * @returns {StoreStaticPage | null}
 */
/* eslint-disable sonarjs/cognitive-complexity */
export const formatStaticPage = (
  queriesData: Array<
    StaticPageQuery | StaticPageAdditionalSectionsQuery | undefined
  >
): StoreStaticPage | null => {
  const staticPage: StoreStaticPage = {};
  const validQueriesData = queriesData.filter(
    (query) => query?.staticPageCollection?.items
  );
  const validSectionData = validQueriesData.map((data) => {
    return data?.staticPageCollection?.items?.[0]?.sectionsCollection?.items;
  });

  validQueriesData.forEach((data) => {
    if (!data?.staticPageCollection?.items) return null;

    const [staticPageData] = data.staticPageCollection.items;

    if (!staticPageData) return null;

    /**
     * Checking if looped request data contains non-section properties
     * and setting them.
     */
    if ('title' in staticPageData && staticPageData.title) {
      staticPage.title = staticPageData.title;
      staticPage.seo = {
        title: staticPageData.seoTitle ?? '',
        description: staticPageData.seoDescription ?? '',
        noIndex: Boolean(staticPageData?.noIndex),
      };
      staticPage.image = staticPageData.image
        ? {
            title: staticPageData.image.title ?? '',
            height: staticPageData.image.height ?? 0,
            width: staticPageData.image.width ?? 0,
            url: staticPageData.image.url ?? '',
          }
        : undefined;
      const jsonData = (staticPageData.content?.json ?? '') as Document;
      const linksData = staticPage.image
        ? undefined
        : (staticPageData.content?.links as StaticPageContentLinks);

      staticPage.content = documentToReactComponents(
        jsonData,
        renderOptions(linksData)
      );
    }
  });

  if (validSectionData && validSectionData[0]?.length) {
    const sectionsToFormat: InputContentfulSections = [];

    validSectionData[0].forEach((section, index) => {
      if (!section) {
        return;
      }

      /**
       * If section has more than one key, it has the full section data, so
       * push it to the `sectionsToFormat` array.
       * Otherwise find the array that does have the data.
       */
      const keyCount = Object.keys(section).length;
      if (keyCount > 1) {
        sectionsToFormat.push(section as InputContentfulSection);
        return;
      }

      /**
       * Loop through the other request data to find if any contains the full
       * request data.
       */
      let matchedSection;
      validSectionData.forEach((queryData) => {
        const sectionData = queryData?.[index];
        if (sectionData && Object.keys(sectionData).length > 1) {
          matchedSection = sectionData;
        }
      });

      if (matchedSection) {
        sectionsToFormat.push(matchedSection);
      }
    });

    staticPage.sections = formatContentfulSections(sectionsToFormat);
  }

  return staticPage;
};

/**
 * Constructing Options object using a custom function
 * to process a bit of logic to resolve our links.
 * Source: https://www.contentful.com/blog/rendering-linked-assets-entries-in-contentful/
 * @param links - StaticPageContentLinks
 * @returns { Options }
 */
export function renderOptions(
  links: StaticPageContentLinks | undefined
): Options | undefined {
  if (!links) return;

  // create an asset map
  const assetMap = new Map();
  // loop through the assets and add them to the map
  for (const asset of links.assets.block) {
    assetMap.set(asset?.sys.id, asset);
  }

  return {
    renderNode: {
      // other options...
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // find the asset in the assetMap by ID
        const asset = assetMap.get(node.data.target.sys.id) || {};

        const { contentType = '', height, title, width, url } = asset;

        // If not image asset do not render.
        // image/jpeg | image/jpg | image/png | image/webp ...
        if (!contentType.startsWith('image/')) return;

        // render the asset accordingly
        return (
          <div className="grid">
            <div
              className={clsx(styles.staticPageContent__img, 'col xs4 l4-9')}
            >
              <Image
                width={width}
                height={height}
                src={url}
                alt={title}
                title={title}
              />
            </div>
          </div>
        );
      },
    },
  };
}
