import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import type { HtmlImageContentProps } from '@we-make-websites/ui-lib';
import { renderOptions } from '@/components/staticPageContext/StaticPageProvider';
import type { HtmlImageContentFragment } from '@/lib/contentful/__generated__/ModuleHtmlImageContent';
import type { StaticPageContentLinks } from '@/lib/contentful/__generated__/types';

export const formatHtmlImageContent = (
  section: HtmlImageContentFragment
): HtmlImageContentProps => {
  const jsonData = (section.htmlContent?.json ?? '') as Document;
  const linksData = section.htmlContent?.links as StaticPageContentLinks;

  return {
    htmlContent: documentToReactComponents(jsonData, renderOptions(linksData)),
    image: {
      url: section.image?.url ?? '',
      width: section.image?.width ?? 0,
      height: section.image?.height ?? 0,
      title: section.image?.title ?? '',
    },
    imagePlacement:
      (section.imagePlacement as HtmlImageContentProps['imagePlacement']) ??
      'Left',
    contentTextAlign:
      (section.contentTextAlign?.toLowerCase() as HtmlImageContentProps['contentTextAlign']) ??
      'left',
  };
};
