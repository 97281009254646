import type { PerksItemProps, PerksProps } from '@we-make-websites/ui-lib';
import type { ModulePerksFragment } from '@/lib/contentful/__generated__/ModulePerks';

export const formatModulePerks = (
  contentEntry: ModulePerksFragment
): PerksProps => {
  return {
    title: contentEntry.title ?? '',
    items: contentEntry.itemsCollection?.items.filter(Boolean).map((item) => {
      const fields: PerksItemProps = {
        title: item?.title ?? '',
        content: item?.body ?? '',
      };

      if (item?.icon) {
        fields.icon = {
          src: item?.icon.url ?? '',
          alt: item?.icon.title ?? '',
          height: item?.icon.height ?? 0,
          width: item?.icon.width ?? 0,
        };
      }

      return fields;
    }),
  };
};
