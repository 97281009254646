import type {
  FormattedContentfulSection,
  InputContentfulSection,
  InputContentfulSections,
} from '@/utils/types/contentfulSections';
import { formatBlogArticleSlider } from './formatBlogArticleSlider';
import { formatBlogArticleStep } from './formatBlogArticleStep';
import { formatBlogArticleTextImage } from './formatBlogArticleTextImage';
import { formatCollectionFeaturedListing } from './formatCollectionFeaturedListing';
import { formatCollectionListingSection } from './formatCollectionListingSection';
import { formatColorSelector } from './formatColorSelector';
import { formatComponentsGocertifyScriptContent } from './formatComponentsGocertifyScriptContent';
import { formatEduCourseListing } from './formatEduCourseListing';
import { formatFindYourSystem } from './formatFindYourSystem';
import { formatGetLookSection } from './formatGetLooksection';
import { formatHtmlContent } from './formatHtmlContent';
import { formatHtmlImageContent } from './formatHtmlImageContent';
import { formatInstagramEmbed } from './formatInstagramEmbed';
import { formatMainHero } from './formatMainHero';
import { formatManualProductRecommendations } from './formatManualProductRecommendations';
import { formatModuleArticleListing } from './formatModuleArticleListing';
import { formatModuleBlogHero } from './formatModuleBlogHero';
import { formatModuleEducationLink } from './formatModuleEducationLink';
import { formatModuleFeaturedArticles } from './formatModuleFeaturedArticles';
import { formatModuleFeaturedTutorial } from './formatModuleFeaturedTutorial';
import { formatModuleHowToApply } from './formatModuleHowToApply';
import { formatModuleMeetEducators } from './formatModuleMeetEducators';
import { formatModulePerks } from './formatModulePerks';
import { formatModuleTestimonies } from './formatModuleTestimonies';
import { formatModuleVideoLesson } from './formatModuleVideoLesson';
import { formatMonthCreator } from './formatMonthCreator';
import { formatNailArtSection } from './formatNailArtSection';
import { formatNotFoundHero } from './formatNotFoundHero';
import { formatServiceEducationListing } from './formatServiceEducationListing';
import { formatShareYourLooks } from './formatShareYourLooks';
import { formatShelf } from './formatShelf';
import { formatSliderHero } from './formatSliderHero';
import { formatVideoCollectionSection } from './formatVideoCollectionSection';
import { formatVideoHero } from './formatVideoHero';
import { formatVideoSection } from './formatVideoSection';
import { formatVideoSectionTabs } from './formatVideoSectionTabs';

/**
 * Pro content array filter
 *
 * If the formatContenfulSections function is called with an optional
 * paramater of proContent === true this filter function will not remove any
 * objects from the items array.
 *
 * If the variable is falsey it will check each module object for the pressence
 * of proContent paramater. If the paramater is set to 'true' then the
 * module will be omitted from the array
 *
 * @param section InputContentfulSection
 * @param useCanViewProContent boolean
 * @returns boolean
 */
function proModuleCheck(
  section: InputContentfulSection,
  useCanViewProContent: boolean
): boolean {
  if (useCanViewProContent) return true;

  return !(section && section.proContent && section.proContent === true);
}

/**
 * Typesafe notEmpty filter
 *
 * Example usesage complexObjectArrayWithNulls.filter(notEmpty)
 * The resulting array will not contain nulls and it's typing will reflect this.
 *
 * @param value unknown | null
 * @returns boolean
 */
export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

/**
 * Format Contentful Sections
 *
 * Function to manage the process of reciving module objects arrays from
 * Contentful GraphQL queries and cleaning them to objects that can be
 * passed as props to matching components
 *
 * The function acts as a switch statement gate for these format functions
 * it also acts as a filter for any modules that are marked as 'proContent'
 * via the proModuleCheck filter function
 */
export const formatContentfulSections = (
  items: InputContentfulSections,
  proContent = false
): FormattedContentfulSection[] => {
  const formattedItems: FormattedContentfulSection[] = [];

  if (!items) return formattedItems;

  items
    /**
     * Filtering is only applied if proContent param is false, this
     * is handled in the filter function
     */
    .filter((section) => proModuleCheck(section, proContent))
    .forEach((section) => {
      if (!section) {
        return;
      }

      switch (section.__typename) {
        case 'MainHero': {
          const formattedData = {
            __typename: section.__typename,
            ...formatMainHero(section),
          };
          formattedItems.push(formattedData);
          break;
        }

        case 'VideoSection': {
          const formattedData = {
            __typename: section.__typename,
            ...formatVideoSection(section),
          };
          formattedItems.push(formattedData);
          break;
        }

        case 'VideoHero': {
          const formattedData = {
            __typename: section.__typename,
            ...formatVideoHero(section),
          };
          formattedItems.push(formattedData);
          break;
        }

        case 'SliderHero': {
          const formattedData = {
            __typename: section.__typename,
            ...formatSliderHero(section),
          };
          formattedItems.push(formattedData);
          break;
        }

        case 'NailArtSection': {
          const formattedData = {
            __typename: section.__typename,
            ...formatNailArtSection(section),
          };

          formattedItems.push(formattedData);
          break;
        }

        case 'CollectionVideoSection': {
          const formattedData = {
            __typename: section.__typename,
            ...formatVideoCollectionSection(section),
          };
          formattedItems.push(formattedData);
          break;
        }

        case 'ShareYourLooks': {
          const formattedData = {
            __typename: section.__typename,
            ...formatShareYourLooks(section),
          };
          formattedItems.push(formattedData);
          break;
        }

        case 'FindYourSystem': {
          const formattedData = {
            __typename: section.__typename,
            ...formatFindYourSystem(section),
          };

          formattedItems.push(formattedData);
          break;
        }

        case 'Shelf': {
          const formattedData = {
            __typename: section.__typename,
            ...formatShelf(section),
          };

          formattedItems.push(formattedData);
          break;
        }

        case 'CreatorOfMonth': {
          const formattedData = {
            __typename: section.__typename,
            ...formatMonthCreator(section),
          };

          formattedItems.push(formattedData);
          break;
        }

        case 'CollectionFeaturedListingSection': {
          const formattedData = {
            __typename: section.__typename,
            ...formatCollectionFeaturedListing(section),
          };

          formattedItems.push(formattedData);
          break;
        }

        case 'NotFoundHero': {
          const formattedData = {
            __typename: section.__typename,
            ...formatNotFoundHero(section),
          };

          formattedItems.push(formattedData);
          break;
        }

        case 'CollectionListingSection': {
          const formattedData = {
            __typename: section.__typename,
            ...formatCollectionListingSection(section),
          };

          formattedItems.push(formattedData);
          break;
        }

        case 'ManualProductRecommendations': {
          const formattedData = {
            __typename: section.__typename,
            ...formatManualProductRecommendations(section),
          };

          formattedItems.push(formattedData);
          break;
        }

        case 'ModuleVideoLesson': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatModuleVideoLesson(section),
          });

          break;
        }

        case 'ModuleBlogHero': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatModuleBlogHero(section),
          });

          break;
        }

        case 'ModuleHowToApply': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatModuleHowToApply(section),
          });

          break;
        }

        case 'ModuleFeaturedTutorial': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatModuleFeaturedTutorial(section),
          });

          break;
        }

        case 'ModuleArticleListing': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatModuleArticleListing(section),
          });

          break;
        }

        case 'ModuleFeaturedArticles': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatModuleFeaturedArticles(section),
          });

          break;
        }

        case 'ModuleTestimonies': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatModuleTestimonies(section),
          });

          break;
        }

        case 'ModuleEducationLink': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatModuleEducationLink(section),
          });

          break;
        }

        case 'ModulePerks': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatModulePerks(section),
          });

          break;
        }

        case 'ModuleCollectionVideoLessonTabs': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatVideoSectionTabs(section),
          });

          break;
        }

        case 'ModuleServiceEducationListing': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatServiceEducationListing(section),
          });

          break;
        }

        case 'InstagramEmbed': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatInstagramEmbed(section),
          });

          break;
        }

        case 'ModuleMeetEducators': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatModuleMeetEducators(section),
          });

          break;
        }

        case 'BlogArticleStep': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatBlogArticleStep(section),
          });

          break;
        }

        case 'BlogArticleTextImage': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatBlogArticleTextImage(section),
          });

          break;
        }

        case 'BlogArticleSlider': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatBlogArticleSlider(section),
          });

          break;
        }

        case 'EduCourseListing': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatEduCourseListing(section),
          });

          break;
        }

        case 'ColorSelector': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatColorSelector(section),
          });

          break;
        }

        case 'GetLook': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatGetLookSection(section),
          });

          break;
        }

        case 'WidgetPlaceholder': {
          formattedItems.push({
            __typename: section.__typename,
            ...section,
          });
          break;
        }

        case 'HtmlContent': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatHtmlContent(section),
          });

          break;
        }

        case 'HtmlImageContent': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatHtmlImageContent(section),
          });

          break;
        }

        case 'ComponentsgocertifyScript': {
          formattedItems.push({
            __typename: section.__typename,
            ...formatComponentsGocertifyScriptContent(section),
          });

          break;
        }
      }
    });

  return formattedItems;
};
