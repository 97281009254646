import type { PLPPromoProps } from '@we-make-websites/ui-lib';
import type { PlpDoubleWidthPromoFragment } from '@/lib/contentful/__generated__/PlpDoubleWidthPromo';

export const formatPlpDoubleWidthPromo = (
  entryContent: PlpDoubleWidthPromoFragment
): PLPPromoProps => {
  return {
    title: entryContent.title ?? '',
    subtitle: entryContent.subtitle ?? '',
    cta:
      entryContent.ctaUrl && entryContent.ctaTitle
        ? {
            url: entryContent.ctaUrl ?? '',
            title: entryContent.ctaTitle ?? '',
            newTab: entryContent.openLinkInNewTab ?? false,
          }
        : undefined,
    variant: entryContent.variant ?? 'Overlay',
    textBackgroundColor: entryContent.textBackgroundColor ?? '#fff',
    textColor: entryContent.textColor ?? '#000',
    image: {
      width: entryContent.image?.width ?? 0,
      height: entryContent.image?.height ?? 0,
      title: entryContent.image?.title ?? '',
      url: entryContent.image?.url ?? '',
    },
    isLoading: false,
  };
};
