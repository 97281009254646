import type {
  CollectionListingCardProps,
  CollectionListingSectionProps,
} from '@we-make-websites/ui-lib';
import type { CollectionListingSectionFragment } from '@/lib/contentful/__generated__/CollectionListingSection';

export const formatCollectionListingSection = (
  entryContent: CollectionListingSectionFragment
): CollectionListingSectionProps => {
  const items = [] as CollectionListingSectionProps['items'];

  if (entryContent.collectionsCollection?.items) {
    entryContent.collectionsCollection.items.forEach((entryItem) => {
      if (!entryItem) {
        return;
      }

      const url = entryItem.handle ? `/collections/${entryItem.handle}` : '';

      const formattedItem: CollectionListingCardProps = {
        title: entryItem.title ?? '',
        description: entryItem.shortDescription ?? '',
        image: entryItem.image?.url ?? '',
        url,
      };

      items.push(formattedItem);
    });
  }

  return {
    title: entryContent.title ?? '',
    items,
  };
};
