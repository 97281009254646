import type {
  BlogArticleSliderProps,
  ImageType,
  VideoPlayerProps,
} from '@we-make-websites/ui-lib';
import type { BlogArticleSliderFragment } from '@/lib/contentful/__generated__/ModuleBlogArticleSlider';
import type {
  BlogArticleSlide,
  VideoSection,
} from '@/lib/contentful/__generated__/types';
import { formatVideoSection } from './formatVideoSection';

export const formatBlogArticleSlider = (
  entryContent: BlogArticleSliderFragment
): BlogArticleSliderProps => {
  const slides: BlogArticleSliderProps['slides'] = [];

  if (!entryContent.slidesCollection?.items.length) return { slides };

  const slidesData = entryContent.slidesCollection?.items;

  slidesData.forEach((slideData) => {
    if (!slideData) return;

    if (slideData.__typename === 'BlogArticleSlide') {
      const slide = formatBlogArticleSliderImage(slideData as BlogArticleSlide);
      slides.push({
        ...slide,
        __typename: 'BlogArticleSlide',
      });
    }

    if (slideData.__typename === 'VideoSection') {
      const slide = formatBlogArticleSliderVideo(slideData as VideoSection);

      slides.push({
        ...slide,
        __typename: 'VideoSection',
      });
    }
  });

  return { slides };
};

const formatBlogArticleSliderImage = (
  slideData: BlogArticleSlide
): ImageType => {
  return {
    height: slideData?.image?.height ?? 0,
    width: slideData?.image?.width ?? 0,
    title: slideData?.image?.title ?? '',
    url: slideData?.image?.url ?? '',
  };
};

const formatBlogArticleSliderVideo = (
  slideData: VideoSection
): VideoPlayerProps => {
  return formatVideoSection(slideData);
};
